div,
button,
input,
select {
  &:focus {
    outline: none !important;
  }
}

.loan-calculator-block {
  color: #252525;

  .range-slider__wrap {
    position: relative;

    &:hover {
      .range-slider__tooltip{
        visibility: visible;
      }
    }

    .range-slider__tooltip {
      background-color: black;
      border-radius: 6px;
      padding: 6px;
      left: 36px;
      min-width: 200px;
      bottom: 21px;
      position: absolute;
      visibility: hidden;
      z-index: 10;

      .range-slider__tooltip__label {
        color: #fff;
        text-align: center;
        font-size: 11px;
      }
    }

    input {
      &[type="range"] {
        appearance: none;
        cursor: pointer;
        background-color: transparent;
        border: none;
        margin-top: 6px;
        margin-right: 0 !important;
        padding-left: 0;
        width: 330px;
      }

      &[type="range"]::-webkit-slider-runnable-track {
        position: relative;
        height: 6px;
        background-color: #E5AF23;
        border-radius: 8px;
      }

      &[type="range"]::-moz-range-track {
        position: relative;
        height: 6px;
        background-color: #E5AF23;
        border-radius: 8px;
      }

      &[type="range"]::-webkit-slider-thumb {
        box-sizing: border-box;
        appearance: none;
        position: relative;
        background-color: white;
        border: 2px solid black;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        top: 50%;
        translate: 0 -50%;
      }

      &[type="range"]::-moz-range-thumb {
        box-sizing: border-box;
        appearance: none;
        position: relative;
        background-color: white;
        border: 2px solid black;
        border-radius: 50%;
        width: 20px;
        height: 20px;
      }

      &[type="range"]::-moz-range-progress {
        height: 6px;
        background-color: #e5af23 !important;
        border-radius: 8px;
      }

      &[type="range"]::-webkit-range-progress {
        height: 6px;
        background-color: #e5af23;
        border-radius: 8px;
      }

      &:after,
      &::before {
        top: 36px !important;
      }
    }

  }

  a {
    color: #252525;
  }

  // .tooltip.in {
  //   opacity: 0.9;
  // }

  // .tooltip {
  //   @media (max-width: 420px) {
  //     left: 50% !important;
  //     transform: translateX(-50%);
  //   }
  // }

  .loan-calc-header {
    font-size: 40px;
    font-weight: 300;
    line-height: 52px;
    margin-bottom: 35px;

    @media (max-width: 1200px) {
      margin-bottom: 10px;
      font-size: 34px;
      margin-top: -40px;
      padding-left: 12px;
    }
  }

  .calculator {
    flex-wrap: nowrap;

    @media (max-width: 768px) {
      padding-top: 12px;
      max-width: 100vw;
    }

    .loan-calc-subhead {
      margin-right: 58px;
      margin-right: 10px;

      .range-slider__wrap {
        text-align: left !important;
        display: block !important;
        justify-content: unset !important;
        min-width: 330px !important;
        margin: 0 !important;
        width: 100%;

        .range-slider__tooltip {
          min-width: 60px;
          bottom: 6px;
          z-index: 10;
        }
      }

      @media (max-width: 768px) {
        padding: 0 30px;
      }
    }

    span {
      font-size: 14px;
      font-style: italic;
      font-weight: 500;
      line-height: 20px;
      color: #585858;
      width: 70%;
      text-align: center;
      display: flex;
      justify-content: center;
      margin: 0 auto;
    }

    label {
      margin-bottom: 18px;
      font-size: 18px;
      font-weight: 300;
      display: block;
      position: relative;

      &:after {
        content: "kr.";
        position: absolute;
        right: 45px;
        top: 66px;
        font-size: 18px;
        font-weight: 500;
        color: #434343;
        z-index: 6;
      }
    }

    input {
      max-width: 330px;
      width: 100%;
      margin-right: 58px;
      font-size: 28px;
      font-weight: 500;
      padding: 13px 21px;
      position: relative;
    }

    .select-wrapper {
      select {
        max-width: 100%;
      }
    }

    .calculate {
      border-radius: 30px;
      border: 2px solid #e5af23;
      height: 60px;
      width: 330px;
      background-color: transparent;
      margin-bottom: 86px;

      @media (max-width: 768px) {
        margin-bottom: 16px;
      }

      span {
        font-size: 20px;
        font-weight: 500;
        text-transform: uppercase;
        font-style: normal;
        color: #252525;
      }
    }

    .calculate-btn {
      margin-top: 46px;
      margin-bottom: 20px;

      .loan-table-header {
        max-width: 345px;

        @media (max-width: 768px) {
          padding: 0;
          margin: 0;
          max-width: 100vw;
        }

        .modal {
          @media (max-width: 768px) {
            z-index: 0;
            padding: 0 30px;
          }

          .modal-header {
            border: none;
            padding: 0;
          }

          label {
            &::after {
              display: none;
            }
          }

          select,
          .select {
            margin-right: 30px;
            width: 270px;
            border-radius: 0;
            background-color: rgba(229, 175, 35, 0.1);
            border: 0;
            border-bottom: 1px solid #e5af23;
            padding: 10px;
            font-size: 14px;
            margin-bottom: 11px;
            display: inline-block;
          }

          select {
            -webkit-appearance: none;
            -moz-appearance: none;
            background-image: url("data:image/svg+xml;utf8,<svg fill='E5AF23' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
            background-repeat: no-repeat;
            background-position-x: 100%;
            background-position-y: 12px;
          }

          .interest-override {
            label {
              @media (max-width: 768px) {
                font-size: 15px;
              }
            }

            .select-wrapper-no-select {
              .multi-interest-override-open {
                max-width: 270px;
                width: 100%;
                border: none;
                margin-bottom: 11px;
                font-size: 28px;
                font-weight: 500;
                padding: 13px 21px;
                position: relative;
                height: 52px;
                font-size: 14px;
                background: transparent;
                color: #454545;

                &::after {
                  content: url(../img/edit.svg);
                  position: absolute;
                  width: 18px;
                  bottom: 0;
                  right: 0;
                }

                @media (min-width: 769px) {
                  padding: 10px !important;
                  height: 49px !important;
                  text-align: left !important;
                  border-bottom: 1px solid #e5af23 !important;
                  border-right: none !important;

                  input {
                    width: 100%;
                    height: 48px;
                    border: 0;
                    border-radius: 0;
                    border-bottom: 2px solid #e5af23;
                    text-align: left;
                    background: transparent;

                    &:focus-within {
                      border: none !important;
                    }
                  }
                }

                @media (max-width: 768px) {
                  max-width: 330px;
                  margin: 0;
                  padding-left: 0;
                  position: relative;
                }
              }

              #multi-interest-override {
                font-size: 16px;
                font-weight: 500;
                padding: 9px 15px;
                margin-bottom: 11px;
              }
            }
          }
        }
      }
    }

    .legal-disclaimer {
      font-size: 14px;
      font-weight: 300;
      text-align: right;

      a {
        border-bottom: 2px solid #e5af23;
      }
    }

    .settings-btn {
      margin-top: 6px;
      padding: 0;
      margin-left: -14px;

      a {
        border-bottom: none;
      }

      .settings-icon {
        display: inline;

        svg {
          height: 23px;
          margin-left: 8px;
        }
      }
    }
  }

  .preview {
    width: 100%;

    .calculator-block {
      .show-calculations {
        button {
          width: 100%;

          &.active {
            background-color: yellow;
          }

          &:after {
            content: "Velja";
          }
        }
      }

      .preview-box {
        .select-wrapper {
          select {
            max-width: 100%;
          }
        }

        .calculate {
          border-radius: 30px;
          border: 2px solid #e5af23;
          height: 60px;
          padding: 0 130px;
          background: transparent;

          span {
            font-size: 20px;
            font-weight: 500;
            text-transform: uppercase;
            font-style: normal;
            color: #252525;
          }
        }

        .calculate-btn {
          margin-top: 50px;
        }

        .legal-disclaimer {
          font-size: 14px;
          font-weight: 300;
          text-align: center;

          a {
            border-bottom: 2px solid #e5af23;
          }

          a.closed {
            margin-top: 100px;
            position: absolute;
            right: 130px;
          }
        }

        .settings-btn {
          margin-top: 6px;

          a {
            border-bottom: none;
          }

          .settings-icon {
            display: inline;

            svg {
              height: 23px;
              margin-left: 8px;
            }
          }
        }
      }
    }

    .table-responsive {
      table {
        tr {
          td {
            @media (max-width: 420px) {
              font-size: 13px;
            }
          }
        }
      }
    }
  }

  .loan-table {
    display: table;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    width: 100%;

    &.payment-table {
      border-top: none;
      border-bottom: none;

      .loan-cell {
        border-right: none;
        padding-top: 20px;
        padding-bottom: 50px;

        @media (max-width: 420px) {
          padding: 0 50px;
          margin-top: -40px;
          margin-bottom: 30px;
        }
      }
    }

    .loan-row {
      display: table-row;

      &.total-payment {
        .loan-label {
          padding-top: 80px;

          @media (max-width: 1200px) {
            padding-top: 40px;
          }
        }
      }

      &.border {
        .loan-cell {
          border-top: 1px solid #dddddd;
          height: 50px;
          font-size: 16px;
        }

        .loan-label {
          font-size: 14px !important;
          border-top: 1px solid #dddddd;
        }
      }

      .chart-container {
        max-width: 269px;
        margin-left: auto;
        margin-right: auto;

        @media (max-width: 768px) {
          max-width: 360px;
        }
      }
    }

    .loan-row-header {
      display: table-row;
      cursor: pointer;

      .loan-cell {
        border-top: 1px solid #dddddd;
        height: 55px;
        font-size: 16px;
        color: #585858;

        &.underline {
          font-weight: 500;

          span {
            border-bottom: 2px solid #e5af23;
          }
        }
      }

      .loan-label {
        position: relative;
        padding-right: 46px !important;

        &:after,
        &:before {
          content: "";
          width: 24px;
          height: 24px;
          border-radius: 12px;
          display: block;
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
        }

        &:after {
          background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIj48cGF0aCBkPSJNMTYuMDAzIDE4LjYyNmw3LjA4MS03LjA4MUwyNSAxMy40NmwtOC45OTcgOC45OTgtOS4wMDMtOSAxLjkxNy0xLjkxNnoiLz48L3N2Zz4=");
          background-position: center;
          background-repeat: no-repeat;
          background-size: 81%;
          filter: invert(1);
        }

        &:before {
          background-color: #e5af23;
        }
      }

      &.open {
        background-color: #585858;

        .loan-cell {
          border: none;
          color: #fff;

          &.loan-label {
            padding: 10px 0;
            font-size: 16px !important;

            &:after {
              transform: rotate(180deg);
              transform-origin: center;
              top: 30%;
            }
          }
        }

        color: white;
      }
    }

    .loan-label-btn {
      border-right: none !important;
    }

    .loan-cell-btn {
      border-right: none !important;
      border-top: 1px solid #dddddd;
      height: 40px;
      border-left: 1px solid #fff;
    }

    .loan-cell {
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      border-right: 1px solid #dddddd;

      &.loan-label {
        text-align: right;
        vertical-align: middle;
        font-style: italic;
        font-size: 1.4rem;
        width: 120px;
        padding-right: 20px;
        line-height: 1.1 !important;

        @media only screen and (min-width: 1200px) {
          width: 200px;
        }
      }

      &.loan-mixed {
        width: 400px;

        &:after {
          content: ".";
          visibility: hidden;
          display: block;
          height: 0;
          clear: both;
        }

        .col-6 {
          width: 50%;
          float: left;
          padding: 5px;
        }
      }

      h3 {
        font-size: 2.2rem;
        font-weight: 800;
        margin-top: 30px;
        margin-bottom: 30px;
        color: #454545;

        @media (max-width: 1200px) {
          font-size: 15px;
          margin-bottom: 16px;
        }
      }

      .payment {
        display: block;
        margin-top: 22px;

        #loan-slider {
          margin-bottom: 0;
        }

        .split {
          display: inline-block !important;
          width: 330px !important;
          font-size: 14px;

          .col-xs-6 {
            display: inline-block;
            text-align: left;
            width: 50%;

            &:last-child {
              text-align: right;
            }
          }
        }
      }

      .payment-wrapper {
        font-size: 4.6rem;
        font-weight: 800;
        border-bottom: 4px solid #e5af23;
        display: inline-block;
        padding-bottom: 10px;

        @media (max-width: 1200px) {
          font-size: 3.2rem;
        }

        .currency {
          font-size: 2.8rem;
          font-weight: 800;
          margin-left: 10px;
        }
      }

      select,
      .select {
        width: 160px;
        border-radius: 0;
        background-color: rgba(229, 175, 35, 0.1);
        border: 0;
        border-bottom: 1px solid #e5af23;
        padding: 10px;
        font-size: 14px;
        margin-top: 11px;
        margin-bottom: 11px;
        display: inline-block;

        @media (max-width: 1200px) {
          margin-top: 8px;
          margin-bottom: 8px;
          width: 140px;
        }
      }

      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url("data:image/svg+xml;utf8,<svg fill='E5AF23' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-repeat: no-repeat;
        background-position-x: 100%;
        background-position-y: 12px;
      }

      .payment-table-button {
        background: #ececec;
        border: 0;
        height: 40px;
        border-radius: 20px;
        font-size: 14px;
        font-weight: 600;
        color: #585858;
        padding: 5px 30px;
        margin-top: 40px;
        position: relative;

        @media (max-width: 420px) {
          margin-top: 0;
        }

        &.open {
          color: #fff;
          background-color: #e5af23;

          &:after {
            content: "";
            display: block;
            position: absolute;
            height: 21px;
            border-left: 1px solid #ddd;
            left: 50%;
            bottom: -35px;

            @media (max-width: 420px) {
              bottom: -26px;
            }
          }
        }
      }
    }
  }
}

.loan-payments-table {
  thead {
    font-size: 14px;
    font-weight: 500;
    background-color: #f2f2f2;
    height: 55px;
  }

  tr {
    height: 55px;
    background-color: transparent;

    td {
      button {
        border-radius: 30px;
        border: 2px solid #e5af23;
        background-color: transparent;
        margin: 10px;
        padding: 6px 24px;
        font-size: 16px;
        font-weight: 500;

        @media (max-width: 420px) {
          margin-left: 0;
        }
      }

      &.center {
        text-align: center;

        @media (max-width: 420px) {
          padding: 0;
          text-align: left;
        }
      }
    }
  }
}

.slider-horizontal {
  width: 330px !important;
  margin-top: 25px;
  margin-bottom: 35px;

  .slider-track {
    height: 6px !important;
    margin-top: -3px !important;
  }

  .slider-selection {
    background-image: none;
    background-color: #e5af23;
  }

  .slider-track-high {
    background-image: none;
    background-color: #e5af23;
  }

  .slider-track-low {
    background-image: none;
    background-color: #e5af23;
  }

  .slider-handle {
    background-image: none;
    background-color: #fff;
    border: 3px solid #000;
  }
}

.loan-payments-table {
  th {
    font-weight: 500;
    font-size: 14px;
    padding-top: 30px;
    background-color: #f2f2f2;
  }
}

@media (max-width: 991.98px) {
  .loan-calculator-block {
    .calculator {
      flex-direction: column;

      input {
        max-width: none;
        margin-right: 0;
        margin-bottom: 10px;
        font-size: 24px;

        @media (max-width: 768px) {
          border: 1px solid #bfbfbf;
        }
      }

      .slider-horizontal {
        width: 100% !important;
        margin-top: 10px;
        margin-bottom: 28px !important;
      }

      .calculate-btn {
        text-align: center;
      }
    }

    .legal-disclaimer {
      display: none;
    }

    #loanSettingsModal {
      display: block;
      position: relative;
      margin-bottom: 30px;

      .modal-dialog {
        margin: 0;

        .modal-content {
          @media (max-width: 9992px) {
            box-shadow: none;
          }

          .modal-header {
            display: none;
          }

          .modal-body {
            label {
              text-align: left;
              margin-bottom: 0;
              margin-top: 20px;

              &:after {
                content: none;
              }
            }

            select,
            input,
            button {
              width: 100%;
              height: 40px;
              border: 0;
              border-radius: 0;
              border-bottom: 2px solid #e5af23;
              text-align: left;
              background: transparent;
            }

            select {
              -webkit-appearance: none;
              -moz-appearance: none;
              background-image: url("data:image/svg+xml;utf8,<svg fill='E5AF23' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
              background-repeat: no-repeat;
              background-position-x: 100%;
              background-position-y: 12px;

              @media (max-width: 768px) {
                height: 40px;
                padding: 12px 10px 0 0;
              }
            }

            .calculator {
              label {
                @media (max-width: 768px) {
                  font-size: 15px;
                }
              }
            }
          }
        }
      }
    }

    .loan-table {
      display: block;
      border: none;

      .loan-row-header {
        display: none;
      }

      .loan-row {
        display: block;

        &.total-payment {
          .loan-cell {
            display: block;
          }
        }

        .loan-cell {
          display: none;
          border: none;

          &.loan-label {
            display: block;
            width: 100%;
            text-align: center;
            padding-top: 10px;
            padding-bottom: 0px;

            @media (max-width: 768px) {
              padding-right: 0;
              padding-top: 20px;
            }
          }

          .payment {
            .slider-horizontal {
              .tooltip {
                @media (max-width: 420px) {
                  left: 50% !important;
                  margin-top: -30px;
                  transform: translateX(-50%);
                }
              }
            }
          }
        }

        &.border {
          border: none !important;

          .loan-cell {
            border: none;
            height: 34px;
          }
        }

        &.total-payment {
          &:after {
            content: ".";
            visibility: hidden;
            display: block;
            height: 0;
            clear: both;
          }

          .loan-indexed,
          .loan-nonindexed {
            position: relative;

            &::after {
              content: url(../img/loan_plus.svg);
              position: absolute;
              width: 28px;
              height: 28px;
              left: 36%;
              border-radius: 50px;
              background-color: #f2f2f2;
              bottom: -16px;
            }
          }

          .loan-mixed {
            .payment {
              position: relative;

              &::after {
                content: url(../img/loan_plus.svg);
                position: absolute;
                width: 29px;
                height: 29px;
                left: 36%;
                bottom: -16px;
                border-radius: 50px;
                background-color: #f2f2f2;
              }
            }
          }

          &+.loan-row {
            margin-top: 30px;
          }

          .loan-cell {
            width: 32%;
            float: left;
            border: 1px solid #dddddd;

            &:nth-child(3) {
              margin-left: 2%;
              margin-right: 2%;
            }

            h3 {
              font-size: 14px;
              margin-bottom: 10px;
            }

            .payment-wrapper {
              font-size: 23px;
              padding-bottom: 0;
              border-bottom: none;
              color: #454545;

              @media (max-width: 420px) {
                font-size: 18px;
              }

              span.currency {
                font-size: 14px;
              }

              &:after {
                content: "Medalafborgun \A fyrsta árs";
                white-space: pre;
                text-align: center;
                display: block;
                width: 100%;
                font-size: 12px;
                font-weight: normal;
                margin-bottom: 30px;
                line-height: 1.2;
                border-top: 2px solid #e5af23;
                padding-top: 5px;
              }
            }

            &.loan-label {
              display: none;
            }
          }
        }
      }

      &.open-loan-indexed {
        .loan-row:not(.total-payment) {
          .loan-cell.loan-indexed {
            display: block;
          }
        }
      }

      &.open-loan-mixed {
        .loan-row:not(.total-payment) {
          .loan-cell.loan-mixed {
            display: block;
            max-width: 95vw;
          }
        }
      }

      &.open-loan-nonindexed {
        .loan-row:not(.total-payment) {
          .loan-cell.loan-nonindexed {
            display: block;
          }
        }
      }

      &.open-loan-indexed {
        .total-payment {
          .loan-indexed {
            border: 2px solid #e5af23;
            position: relative;

            &::after {
              content: url(../img/circle-arrow-down.svg);
              position: absolute;
              width: 29px;
              height: 29px;
              left: 36%;
              bottom: -16px;
            }
          }
        }
      }

      &.open-loan-mixed {
        .total-payment {
          .loan-mixed {
            border: 2px solid #e5af23;
            position: relative;

            .payment {
              &::after {
                content: url(../img/circle-arrow-down.svg);
                position: absolute;
                width: 29px;
                height: 29px;
                left: 36%;
                bottom: -16px;
              }
            }
          }
        }
      }

      &.open-loan-nonindexed {
        .total-payment {
          .loan-nonindexed {
            border: 2px solid #e5af23;
            position: relative;

            &::after {
              content: url(../img/circle-arrow-down.svg);
              position: absolute;
              width: 29px;
              height: 29px;
              left: 36%;
              bottom: -16px;
            }
          }
        }
      }
    }
  }
}

.border-last-type {
  background-color: transparent !important;
}